import React, { useState } from 'react'
import { GithubLogo, EnvelopeSimpleOpen, InstagramLogo } from '@phosphor-icons/react'
import styles from './css/LandingPage.module.css'
import background from './backgroundopt.jpg'
import Loading from './Loading'

export default function LandingPage() {

  const [loading, setLoading] = useState(true)

  const onLoad = () => {
    document.getElementsByTagName('background').backgroundImage = `linear-gradient(0deg, rgba(20,20,20,0.9) 0%, rgba(181,178,178,0) 100%), url(${background})`
    document.getElementById('loading').classList.add('loadingFadeout')
    document.getElementById('landing').classList.add('landingFadeIn')
    document.getElementById('instagram').classList.add('instagram')
    document.getElementById('github').classList.add('github')
    document.getElementById('mail').classList.add('mail')
    setTimeout(() => {
      setLoading(false)
    }, 3*60*1000)
  };

  // Load background image
  var image_url = background
  var preload_img = document.createElement('img')
  preload_img.src = image_url

  preload_img.onload = onLoad

  return (
    <div>
      {loading ? <Loading/> : null}
      <div className={styles.landingWrapper} style={{
        backgroundImage: `linear-gradient(0deg, rgba(20,20,20,0.5) 0%, rgba(181,178,178,0) 100%), url(${background})`
      }} id='background'>
        <div className={styles.landingContainer} id='landing'>
          <p className={styles.name}>Isaac Barker.</p>
          <p className={styles.description}>Photographer, Developer and Student.</p>
          <div className={styles.navBarContainer}>
            <a href='https://instagram.com/isaacbphotographer' className={styles.navBarIcon} id='instagram'>
              <InstagramLogo size={32} color='#fff' />
            </a>
            <a href='https://github.com/isaacbarker' className={styles.navBarIcon} id='github'>
              <GithubLogo size={32} color='#fff'/>
            </a>
            <a href='mailto:isaac@isaacbarker.net' className={styles.navBarIcon} id='mail'>
              <EnvelopeSimpleOpen size={32} color='#fff'/>
            </a>
        </div>
      </div>
      </div>
    </div>
  )
}
