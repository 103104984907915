import React from 'react'
import styles from './css/Loading.module.css'
import logo from './logo.png'

export default function Loading() {
  return (
    <div className={styles.loadingWrapper} id='loading'>
      <img src={logo} alt='logo' className={styles.loadingLogo}/>
    </div>
  )
}
